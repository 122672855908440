@tailwind base;
@tailwind components;
@tailwind utilities;

.form-label {
    @apply text-justify block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2
}
  
.form-field {
    @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow
}

.form-button {
    @apply bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow
}

.form-error-message {
    @apply text-red-500 text-xs italic
}

.form-group {
    @apply mb-4
}

.sidebar-item {
    @apply flex flex-row w-full text-justify px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none focus:shadow
}

.sidebar-item-highlight {
    @apply bg-gray-300 dark:bg-gray-600
}

.MuiInput-root:focus-within {
    border: 1px #4BB543 solid;
}

.sbui-checkbox-container--medium .sbui-checkbox__label-container__label__span {
    color: white !important;
}

.sbui-input--with-icon, .sbui-input {
    background-color: transparent !important;
    color: white !important;
    border-radius: 20px !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.sidebar-item {
    background-color: transparent;
}

.PhoneInputInput {
    background-color: transparent;
    --tw-border-opacity: 1;
    display: block;
    border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
    height: 38px;
    padding: 8px 16px;
}

.sbui-btn {
    border-radius: 20px !important;
}


@media (prefers-color-scheme: dark) {}

@media (prefers-color-scheme: light) {}

.MuiInputBase-root {
    border-radius: 10px !important;
}

.loading {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1;
}

#appbar .MuiMenu-paper{
    left: 80vw !important;
    right: 16px !important;
    top:45px !important;
}

.css-fj1p6v-MuiTypography-root {
    line-height: unset !important;  
    padding-bottom: 2px;
}

.lang-switch-btn {
    margin-left: 1.5rem !important;
}

.css-1x7v041-MuiGrid-root {
    margin-left: -15px !important;

}
.css-1x7v041-MuiGrid-root>.MuiGrid-item{
    padding-left: unset !important;
}

.IconButton {
    padding-right: 2rem !important;
}

html[lang="ar"] #appbar .MuiMenu-paper{
    right: 80vw !important;
    left: 16px !important;
    top:45px !important;
}


html[lang="ar"] .footerComponent {
    right: 25% !important;
    width: 50%;
}

html[lang="ar"] .IconButton {
    padding-right: unset !important;
    padding-left: 2.5rem;
}
html[lang="ar"] body {
    font-family: 'Cairo', sans-serif !important;
  }
  
html[lang="ar"] body[dir="rtl"] {
text-align: right;
}

html[lang="ar"] .MuiDrawer-paper {
left: unset !important;
border-left: 1px solid;
}

html[lang="ar"] .searchIcon {
    left: 0;
}

html[lang="ar"] .mainBox {
    margin-right: 40px;
}

.css-8u39c-MuiTypography-root {
    text-align: center;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 23px !important; /* 127.778% */   
}
.navIcon {
    text-align: right;
}

.merchantList {
    margin-left: 2rem !important;
}

.merchantList button{
    gap: .75rem;
}

.MuiTablePagination-root {
    direction: ltr;
}

.cardContent {
    margin: 5px 0;
}

html[lang="ar"] .MuiStepLabel-root {
    display: flex;
    gap: .75rem !important;
}

html[lang="ar"] .merchantList {
    margin-left: unset !important;
    margin-right: 2rem !important;
}

html[lang="ar"] .listItem{
    display: flex;
    text-align: right;
    flex-direction: column;
    align-items: flex-start;
}

html[lang="ar"] .navIcon {
    text-align: left !important;
    padding-left: unset !important;
}

html[lang="ar"] .navHeader {
    gap: unset !important;
}

html[lang="ar"] .phoneNumber {
    direction: ltr !important;
    padding-left: 5rem;
}

html[lang="ar"] .phoneNumberHeader {
    padding-right: 3rem
}


html[lang="ar"] .MuiListItemButton-root{
    gap: .75rem;
    min-width: 300px;
    text-align: right;
}

html[lang="ar"] .searchBar label{
    left: unset;
    right: 1.5rem;
}

html[lang="ar"] .searchBar .MuiInputLabel-shrink{
    right: 1rem !important;
}

html[lang="ar"] .MuiFormHelperText-root {
    text-align: right !;
}

html[lang="ar"] .questionIcon {
    left: 20px !important;
    right: unset !important;
}

html[lang="ar"]  #demo-simple-select-label{
    text-align: right;
}

html[lang="ar"] .lang-switch-btn {
    margin-left: unset !important;
    margin-right: 1.5rem !important;
}

html[lang="ar"] .loading-button {
    gap: .5rem;
}

html[lang="ar"] .MuiInputLabel-root{
    right: 25px;
    left: auto;
}

html[lang="ar"] .move-label label{
    right: 22px !important;
}

html[lang="ar"] .format-label label{
    right: 15px !important;
}
html[lang="ar"] .format-label .MuiInputLabel-shrink{
    right: 15px !important;
}

html[lang="ar"] .store-label-settings label{
    right: 43px !important;
}
html[lang="ar"] .store-label-settings .MuiInputLabel-shrink{
    right: 10px !important;
}

html[lang="ar"] .store-label label{
    right: 43px !important;
}
html[lang="ar"] .store-label .MuiInputLabel-shrink{
    right: 15px !important;
}

html[lang="ar"] .amount .MuiInputLabel-shrink{
    right: -10px !important;
}

html[lang="ar"] .title .MuiInputLabel-shrink{
    right: 15px !important;
}

html[lang="ar"] .expiryDate .MuiInputLabel-shrink{
    right: 10px !important;
}
html[lang="ar"] .paymentLink .MuiInputLabel-shrink{
    right: 10px !important;
}



html[lang="ar"] .MuiInputLabel-shrink{
    right: 0px !important;
}
html[lang="ar"] .firstName .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .lastName .MuiInputLabel-shrink{
    right: 7px !important;
}

html[lang="ar"] .ID .MuiInputLabel-shrink{
    right: 16px !important;
}
html[lang="ar"] .createdAt .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .type .MuiInputLabel-shrink{
    right: 20px !important;
}
html[lang="ar"] .rAmount .MuiInputLabel-shrink{
    right: 18px !important;
}
html[lang="ar"] .refundStatus .MuiInputLabel-shrink{
    right: 18px !important;
}
html[lang="ar"] .feeVat .MuiInputLabel-shrink{
    right: -32px !important;
}
html[lang="ar"] .amwalFee .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .installmentDuration .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .totalFee .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .amwalFee .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .installmentFee .MuiInputLabel-shrink{
    right: 7px !important;
}
html[lang="ar"] .refundAmount .MuiInputLabel-shrink{
    right: 16px !important;
}
html[lang="ar"] .merchantPayout .MuiInputLabel-shrink{
    right: 10px !important;
}

html[lang="ar"] .partialRefund .MuiInputLabel-shrink{
    right: -10px !important;
}

html[lang="ar"] .singleUse .MuiInputLabel-shrink{
    right: -5px !important;
}

html[lang="ar"] .email{
    right: 7px !important;
}
html[lang="ar"] .roleField label{
    right: 45px !important;
}

html[lang="ar"] .roleField .MuiInputLabel-shrink{
    right: 20px !important;
}

html[lang="ar"] .description .MuiInputLabel-shrink{
    right: 15px !important;
}
html[lang="ar"] .searchPaymenLink .MuiInputLabel-shrink{
    right: 22px !important;
}
html[lang="ar"] .storeType label{
    right: 45px !important;
}

html[lang="ar"] .storeType .MuiInputLabel-shrink{
    right: 10px !important;
}

html[lang="ar"] .emailAddress .MuiInputLabel-shrink{
    right: 5px !important;
}
html[lang="ar"] .freelanceNumber .MuiInputLabel-shrink{
    right: 5px !important;
}
html[lang="ar"] .businessName .MuiInputLabel-shrink{
    right: 10px !important;
}
html[lang="ar"] .maroof .MuiInputLabel-shrink{
    right: 15px !important;
}

html[lang="ar"] .storeCity .MuiInputLabel-shrink{
    right: 5px !important;
}
html[lang="ar"] .websiteURL .MuiInputLabel-shrink{
    right: -15px !important;
}
html[lang="ar"] .businessType .MuiInputLabel-shrink{
    right: 10px !important;
}

html[lang="ar"] .monthlyRevenue .MuiInputLabel-shrink{
    right: -15px !important;
}
html[lang="ar"] .beneficiaryName .MuiInputLabel-shrink{
    right: 5px !important;
}
html[lang="ar"] .bankIBAN .MuiInputLabel-shrink{
    right: 8px !important;
}

html[lang="ar"] .MuiOutlinedInput-notchedOutline {
text-align: right;
}
