html[lang="ar"] * {
  font-family: 'Cairo', sans-serif !important;
}
* {
  font-family: 'Inter', sans-serif !important;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.single-use-radio-group {
  justify-content: space-between;
  width: 100%;
}
.single-use-radio-button {
  border-radius: 10px;
  width: 49%;
  border: 1px solid rgba(0, 0, 0, 0.23);
}